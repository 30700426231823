
//	Dependencies
import React from 'react';

// App
import { HomeView } from 'ui/views';


//
//	RAYC / Pages / Index
//


function IndexPage( props ) {
	return <HomeView { ...props } />;
}

export default IndexPage;
